import React from 'react'

export default ({ className, style }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 149 146.7' className={className} style={style}>
    <path d='M59.58,35H45.75c-7.58.12-9.35,1.89-9.79,9.17V59.48' />
    <path d='M113,58.66V44.84c-.11-7.58-1.88-9.35-9.16-9.8H88.61' />
    <path d='M36,87.22v15.27c.44,7.28,2.21,9.05,9.79,9.16H59.58' />
    <path d='M88.61,111.65h15.27c7.28-.44,9.05-2.21,9.16-9.79V88' />
    <line style={{fill: 'none', stroke: 'rgb(181, 197, 223)', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '8px'}} x1='74.5' y1='59.71' x2='74.5' y2='86.99' />
    <line style={{fill: 'none', stroke: 'rgb(181, 197, 223)', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '8px'}} x1='88.14' y1='73.35' x2='60.86' y2='73.35' />
  </svg>
)
