import React from 'react'

export default ({ className, style }) => (
  <svg width='13' height='14' viewBox='0 0 13 14' xmlns='http://www.w3.org/2000/svg' className={className} style={style}>
    <g strokeWidth='1' strokeLinecap='round' fill='none'>
      <path d='M0.5,11.5 C0.5,11.5 4.4593099,11.2023926 6.5,11.2023926 C8.4593099,11.2023926 12.5,11.5 12.5,11.5' />
      <path d='M2.64990234,11.3413086 L2.64990234,3.5 C2.64990234,3.5 2.70980708,2.20868317 3.69677734,1.35302734 C4.68374761,0.497371522 6.04737394,0.445800781 6.60229492,0.445800781 C7.28309843,0.445800781 8.77071845,0.576004326 9.60009766,1.35302734 C10.4001882,2.10261056 10.5546875,3.5 10.5546875,3.5 L10.5546875,11.3413086' />
      <path d='M4.5,11.8999996 C4.5,11.8999996 5.02880859,13.3414059 6.5,13.3414059 C7.97119141,13.3414059 8.5,11.8999996 8.5,11.8999996' />
    </g>
  </svg>
)
