import React from 'react'

export default ({ className, style }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 149 146.7' className={className} style={style}>
    <path d='M74.5,121.85c-22.33,0-40.5-21.76-40.5-48.5s18.17-48.5,40.5-48.5S115,46.6,115,73.35,96.83,121.85,74.5,121.85Zm0-90C56,31.85,41,50.46,41,73.35s15,41.5,33.5,41.5S108,96.23,108,73.35,93,31.85,74.5,31.85Z' />
    <rect x='40' y='55.35' width='69' height='7' />
    <rect x='71.5' y='58.85' width='7' height='60' />
    <path d='M36,76.6H15a3.5,3.5,0,0,1,0-7H36a3.5,3.5,0,0,1,0,7Z' />
    <path d='M134,76.6H113a3.5,3.5,0,0,1,0-7h21a3.5,3.5,0,0,1,0,7Z' />
    <path d='M30.42,120.67a3.5,3.5,0,0,1-2.48-6L42.79,99.85a3.5,3.5,0,1,1,5,5L32.89,119.65A3.49,3.49,0,0,1,30.42,120.67Z' />
    <path d='M104.47,46.87a3.5,3.5,0,0,1-2.47-6l14.85-14.85a3.5,3.5,0,0,1,5,4.95L107,45.85A3.51,3.51,0,0,1,104.47,46.87Z' />
    <path d='M45.27,47.36a3.48,3.48,0,0,1-2.48-1L27.94,31.48a3.5,3.5,0,0,1,4.95-4.95L47.74,41.38a3.49,3.49,0,0,1,0,4.95A3.46,3.46,0,0,1,45.27,47.36Z' />
    <path d='M119.32,121.67a3.49,3.49,0,0,1-2.47-1L102,105.79a3.5,3.5,0,1,1,5-5L121.8,115.7a3.5,3.5,0,0,1-2.48,6Z' />
  </svg>
)
